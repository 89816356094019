<template>
  <div class="flex flex-row gap-x-6">
    <div class="flex">
      <slot name="icon" />
    </div>

    <div class="flex flex-col items-start gap-y-2">
      <slot name="text" />
    </div>
  </div>
</template>
